import getConfig from "@hooks/getConfig";
import PageBuilder from "@modules/PageBuilder";
import { GetServerSideProps } from "next";
import { ReactElement } from "react";

function Page(props): ReactElement {
  return <PageBuilder {...props} />;
}
export default Page;

function setCurrentPageParams(pageData: any) {
  let selectedVariant = null;
  if (pageData?.Variants !== undefined) {
    var focusedVariants = pageData.Variants.filter((variant) => variant.IsFocused === true);
    selectedVariant = focusedVariants[0]?.Sku;
  }

  let selectedVariantQueryParam = "";
  if (selectedVariant != null) {
    selectedVariantQueryParam = `&sku=${selectedVariant}`;
  }
  let pageIdQueryParam = "";
  if (pageData.Id != null) {
    pageIdQueryParam = `&pageId=${pageData.Id}`;
  }
  return { selectedVariant: selectedVariant, pageIdQueryParam: pageIdQueryParam, selectedVariantQueryParam: selectedVariantQueryParam };
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  // console.log("[[...slug]].tsx::getServerSideProps", context.params.slug);
  const { params, locale } = context;
  let trueLocale = locale === "default" ? "en" : locale;

  // Add whatever `Cache-Control` value you want here
  context.res.setHeader("Cache-Control", "public, s-maxage=60, stale-while-revalidate=299");
  try {
    const { apiEndpoint, blobStoreEndpoint, edgeEndpoint } = getConfig();
    const slug = params.slug || ["en"];
    const dataUrl = [apiEndpoint, trueLocale].concat(slug).join("/") + "?json";

    const staticDataUrl = [apiEndpoint, "api", "staticcontent"].join("/");
    const translationsUrl = [apiEndpoint, "api", "translations", "json", trueLocale || "en"].join("/");

    const allData = await Promise.all([
      fetch(dataUrl).then((response) => response.json()),
      fetch(staticDataUrl).then((response) => response.json()),
      fetch(translationsUrl).then((response) => response.json()),
    ]);
    const [pageData, staticData, messages] = allData;

    const { pageIdQueryParam, selectedVariantQueryParam } = setCurrentPageParams(pageData);
    const marketDataUrl = [apiEndpoint, "api", `marketcontent?epslanguage=${trueLocale || "en"}&marketId=${slug[0] || "en"}${pageIdQueryParam}${selectedVariantQueryParam}`].join("/");
    var marketData = await fetch(marketDataUrl).then((response) => response.json());

    const marketId = marketData?.MarketId;
    const priceListBlobUrl = [blobStoreEndpoint, `${marketId}.json`].join("/");
    const priceListData = await fetch(priceListBlobUrl).then((response) => response.json());
    return {
      props: {
        priceListData: priceListData,
        pageData: {
          slug: slug.toString().toLowerCase(),
          variant: "",
          ...pageData,
        },
        marketData: marketData,
        messages: messages.Translations,
        staticData: staticData,
      },
    };
  } catch (error) {
    return { notFound: true };
  }
};
